<template lang="pug">
  div
    TheHeader
    .wrapper.policy
      .policy_wrap
        .content
          h1 {{ $t('contacts.mainTitle') }}
          div(
            v-for="(part, partIdx) in $t('contacts.content')"
            :key="'contacts#part#'+partIdx")
            p(v-if="part.title")
              strong(v-html="part.title")
            p(
              v-for="(text, textIdx) in part.text"
              :key="'contacts#part#'+partIdx+'text#'+textIdx"
              v-html="text")
</template>

<script>
import TheHeader from '@/components/TheHeader';

export default {
  components: { TheHeader },
  computed: {
    supportLink() {
      switch (this.$i18n.locale) {
        case 'ru':
          return 'https://support.kickex.com/hc/ru/requests/new?ticket_form_id=360000698379';
        case 'fr':
          return 'https://support.kickex.com/hc/fr-fr/requests/new?ticket_form_id=360000698379';
        case 'zh':
          return 'https://support.kickex.com/hc/zh-cn/requests/new?ticket_form_id=360000698379';
        default:
          return 'https://support.kickex.com/hc/en-us/requests/new?ticket_form_id=360000698379';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 38px;
    margin-bottom: 20px;
  }
</style>
